import ITBlog from "../models/Blog";
import { getCollectionByName } from "../utils/firebase";
import { getUser } from "../utils/auth";


const ITBlogCollection = getCollectionByName("ITBlog");

export function getViewCountByBlogNewsId(id) {
    return ITBlogCollection
        .doc(id)
        .get()
        .then((doc) => {
            if (doc.exists) {
                return doc.data();
            } else {
                const newITBlog = new ITBlog();
                newITBlog.count = 0;
                newITBlog.likeUser = [];
                return newITBlog;
            }
        });
}

export function addOrUpdateITBlog(news, id, count, likeUser) {
    const newITBlog = new ITBlog();
    // update view count, likeUser
    newITBlog.count = count;
    newITBlog.likeUser = likeUser;
    newITBlog.id = id;
    newITBlog.news = news;

    return ITBlogCollection.doc(id).set(Object.assign({}, newITBlog));
}

export function getLikedBlogByUser() {
    return ITBlogCollection.get()
        .then((querySnapshot) => {
            var likedBlog = [];
            querySnapshot.forEach((doc) => {
                if (doc.data().likeUser.indexOf(getUser().uid) !== -1) {
                    likedBlog.push({
                        news: doc.data().news,
                        id: doc.data().id
                    }
                    )
                }
            });
            return likedBlog;
        });
}